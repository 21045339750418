@font-face {
  font-family: 'Space Grotesk';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: local('Space Grotesk'), url('../static/fonts/SpaceGrotesk-SemiBold.woff') format('woff'),
    url('../static/fonts/SpaceGrotesk-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Space Grotesk';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: local('Space Grotesk'), url('../static/fonts/SpaceGrotesk-Medium.woff') format('woff'),
    url('../static/fonts/SpaceGrotesk-Medium.woff2') format('woff2');
} 


* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 400;
}

.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.pulsating-circle {
  margin: 90pt 0 0 20pt;
  transform: translateX(-50%) translateY(-50%);
  width: 20px;
  height: 20px;
}

.pulsating-circle::before {
  content: '';
  position: relative;
  display: block;
  width: 200%;
  height: 200%;
  box-sizing: border-box;
  margin-left: -50%;
  margin-top: -50%;
  border-radius: 45px;
  background-color: #D14124;
  animation: pulse-ring 1.5s cubic-bezier(0.455, 0.60, 0.85, 0.9) infinite;
}

.pulsating-circle::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #D14124;
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0,0,0,.3);
  animation: pulse-dot 1.5s cubic-bezier(0.3, 0.03, 0.515, 0.955) infinite;
}

@keyframes pulse-ring {
  0% {
    transform: scale(.33);
  }
  80%, 100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(.8);
  }
}